<template>
  <div class="container mt-5 mb-5">
    <b-img :src="imgLogo" alt="logo" class="logo" />
    <b-alert
        variant="primary"
        show
    >
      <div class="alert-body">
        <p>
          <strong>Info: </strong>
          <span>This layout is used in Authentication &amp; Miscellaneous page. Please check the </span>
          <b-link
              class="alert-link"
              href="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/guide/layout/page-layout-examples.html#layout-blank"
              target="_blank"
          >Layout Blank documentation</b-link>
          <span> for more details.</span>
        </p>
      </div>
    </b-alert>
    <imprint-german></imprint-german>
    <imprint-english></imprint-english>
  </div>
</template>

<script>
import { BAlert, BLink, BImg } from 'bootstrap-vue'
import {email, required} from "@core/utils/validations/validations";
import ImprintGerman from "@/views/pages/content/ImprintGerman";
import ImprintEnglish from "@/views/pages/content/ImprintEnglish";
export default {
  components: {
    ImprintEnglish,
    BAlert,
    BLink,
    BImg,
    ImprintGerman,
  },
  data() {
    return {
      imgLogo: require('@/assets/images/logo/intellyscale.svg'),
    }
  },
}
</script>
<style lang="scss">
  .logo {
    width: 200px;
  }
</style>
